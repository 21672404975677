import store from '@/Store';

export const permissionCheck = function (permission) {
  let user = store.getters.getUser;
  if(user !== null){
    if(user.permissions.indexOf(permission) !== -1){
      return true;
    }
  }
  return false;
}
