<template src="./courses.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "Courses",
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  data: function () {
    return {
      fields: [
        {
          key: 'date',
          label: 'Datum',
          formatter: value => { return this.$options.filters.formatDate(value) },
        },
        {
          key: 'price',
          label: 'Preis',
          formatter: value => {
            return value + ' €'
          },
        },
        { key: 'name', label: 'Thema'},
        { key: 'participants', label: 'Personen'},
        { key: 'city', label: 'Ort'},
        { key: 'actions', label: '', tdClass: 'text-right'},
      ],
      courses: [],
      meta: {}
    }
  },
  methods: {
    loadCourses(ctx, callback) {
      let self = this;
      const params = '?page=' + ctx.currentPage + '&per_page=' + ctx.perPage;
      Http.request('GET', '/courses' + params).then(function (courses) {
        self.meta = courses.meta;
        self.courses = courses.data;
        callback(courses.data)
      });
      return null
    },
    setSeminarFull: function (course) {
      this.$bvModal.msgBoxConfirm('Möchtest du dieses Seminar als ' + (course.full ? ' nicht ' : '') + ' ausgebucht markieren?', {
          headerBgVariant: 'primary',
          title: 'Seminar ausgebucht',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if(value === true){
            let full = course.full ? 0 : 1;
            Http.request('POST', '/courses/' + course.id + '/full/' + full).then(() => {
              course.full = full === 1;
            });
          }
        });
    },
    deleteSeminar: function (course) {
      let self = this;
      let confirmHtml = self.$createElement('div', { domProps: {
        innerHTML: 'Möchtest du dieses Seminar wirklich löschen?<br>Bitte beachte, dass ggf. angelegte Social Media Posts automatisch mit gelöscht werden.'
      }});
      self.$bvModal.msgBoxConfirm([confirmHtml], {
          headerBgVariant: 'primary',
          title: 'Seminar löschen',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if(value === true){
            Http.request('DELETE', '/courses/' + course.id).then(() => {
              if(typeof(self.$refs.courseTable) !== "undefined"){
                self.$refs.courseTable.refresh();
              }
              else{
                self.$router.push('/website/courses');
              }
            });
          }
        });
    }
  }
}

</script>

<style scoped></style>
